.dock-door-popup-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dock-popup-bottom-section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 0px;
}

.dock-door-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--modal-bg);
    position: fixed;
    z-index: 1008;
    top: 0;
    left: 0;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dock-door-wrapper-container {
    overflow-y: scroll;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    cursor: pointer;
    border-radius: 30px;
}

.dock-door-details-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: 10px;
}

.dock-door-detail-left-section {
    display: flex;
    flex: .7;
    flex-direction: column;
    width: 100%;
}

.left-detail-title-section {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 30px;
    margin-top: 10px;
}

.left-detail-data-section {
    width: 100%;
    height: 40%;
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;
}

.detail-section {
    width: 140px;
    margin: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.detail-section h4 {
    color: var(--tertiary-white);
    font-weight: 400;
    text-align: left;
}

.detail-section p {
    margin-top: -20px;
    color: var(--grey);
    font-weight: 500;
    text-align: left;
}

.dock-door-detail-right-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: .3;
    padding-right: 20px;
}

.dock-door-popup {
    width: 90%;
    background-color: var(--white);
    border-radius: 30px 30px 4px 4px;
    border: 5px solid var(--lite-border);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
    z-index: 1008 !important;
}

.dock-popup-top-section {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
    background-color: var(--liteBlue);
    border-radius: 25px 25px 0px 0px;
    border-bottom: 5px solid var(--lite-border);
}

.dock-popup-small {
    padding: 0px 5px;
    border-radius: 10px;
}

.dock-popup {
    height: 5vh;
    margin: 2px 0px;
    font-size: .6vw;
    color: var(--white);
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dock-popup h4 {
    text-align: center;
}

.dock-popup-big {
    width: 85%;
    border-radius: 10px;
    margin: 2px 0px;
    display: flex;
    flex-direction: row;
}

.dock-popup-big-complex-right , .dock-popup-big-complex-left {
    flex: 1;
    margin: 0px .6px;
    border-radius: 10px;
}

.ant-progress-bg {
    height: 20px !important;
}


.dock-door-brief-loader {
    height: 100%;
    margin: 50px 30px 30px 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    border-radius: 10px;
}

.dock-door-brief-loader h4 {
    margin-right: 10px;
}

.spinner {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: 
        radial-gradient(farthest-side,var(--primary-color) 94%,#0000) top/4px 4px no-repeat,
        conic-gradient(#0000 30%,var(--primary-color));
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
    animation:s3 1s infinite linear;
}

@keyframes s3{ 
    100%{transform: rotate(1turn)}
}
