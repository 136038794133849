.active-shipment-status-container {
    margin: 30px;    
    background-color: var(--white);
    padding-left: 25px;
    border-radius: 10px;
    overflow-x: hidden;
}
.active-shipment-header-section {
    display: flex;
    justify-content: flex-start;
}

.active-shipment-header-section h4 {
    font-size: 16px;
    font-weight: 500;
    color: var(--grey);
}
.active-shipment-status-section {
    display: flex;
    flex-direction: row;
    margin-top: -15px;
    padding: 5px 10px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.active-shipment-loader-alignment {
    justify-content: center;
    align-items: center;
    display: flex;
}

.rm-loader>.active-shipment-status-section {
    justify-content: center;
    align-items: center;
}

.active-shipment-status-section .active-shipment-status-box {
    min-width: 9vw;
    padding-left: 5px;
    padding-right: 5px;
    max-height: 90px;
    margin-right: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1px solid var(--lite-border);
}

.active-shipment-status-section .active-shipment-status-box .active-shipment-status-top-section {
    width: 100%;
    height: 25px;
    border-radius: 10px 10px 0px 0px;
}

.active-shipment-status-section .active-shipment-status-box .active-shipment-status-top-section p{
    font-weight: 200;
    font-size: 40px;
    color: var(--black);
    margin-top: 5px;
}

.active-shipment-status-section .active-shipment-status-box .active-shipment-status-bottom-section {
    width: 100%;
}

.active-shipment-status-section .active-shipment-status-box .active-shipment-status-bottom-section p {
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    margin-top: 40px;
    word-break: break-all;
}

.active-shipment-status-section-wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.spinner {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: 
        radial-gradient(farthest-side,var(--primary-color) 94%,#0000) top/4px 4px no-repeat,
        conic-gradient(#0000 30%,var(--primary-color));
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
    animation:s3 1s infinite linear;
}

@keyframes s3{ 
    100%{transform: rotate(1turn)}
}


@media only screen and (max-width: 780px) {
    .map-container {
        margin: 20px;
    }
}

@media only screen and (max-width: 430px) {
    .active-shipment-status-container {
        margin: 20px;
    }
    .active-shipment-status-section  .active-shipment-status-box {
        min-width: 28vw;
    }
}

@media only screen and (max-width: 380px) {
    .active-shipment-status-section  .active-shipment-status-box {
        min-width: 40vw;
    }
}

@media only screen and (max-width: 330px) {
    .active-shipment-status-section  .active-shipment-status-box {
        min-width: 40vw;
    }
}
@media only screen and (min-width: 2560px) {
    .active-shipment-status-container {
        width: 97%;
    }
    .active-shipment-status-box {
        min-width: 5.5vw !important;
    }
    .active-shipment-status-top-section, .active-shipment-status-bottom-section {
        width: 100%;
    }

}