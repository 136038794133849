.App {
  text-align: center;
  position: relative;
}

body {
  background-color: var(--liteBlue);
}

:root {
  --liteBlue: #F1F3F5;
  --grey: #414C56;
  --white: #FFFFFF;
  --primaryBlue:#20242B;
  --secondary-text:#414C56;
  --purpleBlue:#4647FE;
  --black:#000000;
  --primary-color:#006098;
  --primary-blue:#4461F2;
  --faded-primary-blue:#EDF0FE;
  --lite-border:#D9D9D9;
  --tertiary-white:#65727D;
  --error-red:#BF2E3A;
  --error-red-lite:#ead5d9;
  --modal-bg:rgba(0, 0, 0, 0.5);
  --disabled-white:#EEEEEE;
  --disabled-loading-state:#E4E4E4;
  --disabled-btn-bg:#ffffff66;
  --line-color:#FAFAFA;
  --error-modal-bg:#f9f1e8;
  --warning-title-color:#c36e19;
}

.leaflet-div-icon {
  background-color: none !important;
  border: none !important;
  border-radius: 6px !important;
}

.loggin-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.leaflet-container {
  background-color: #FFFFFF !important;
}