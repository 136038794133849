.time-range-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: var(--modal-bg);
    z-index: 1008;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.time-range-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--white);
    width: 900px;
    height: 300px;
    border-radius: 25px;
    padding: 10px 25px;
}

.time-range-top-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.time-range-popup-close-btn {
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.time-range-top-section p {
    font-size: 20px;
    font-weight: 500;
}

.time-range-middle-section {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.picker-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.time-range-left-panel, .time-range-right-panel {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.time-range-pickers {
    display: flex;
    flex-direction: row;
}

.time-range-info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.time-range-info p{
    font-size: 12px;
    font-weight: 400;
    color: var(--grey);
}

.time-frame-section {
    display: flex;
    align-items: flex-start;
    margin-left: -8px;
    margin-top: 7px;
}

.time-frame-btn {
    height: 30px;
    padding: 2px 15px;
    background-color: white;
    border: .5px solid var(--lite-border);
    border-radius: 25px;
    color: var(--primary-blue);
}

.time-frame-btn-selected {
    border-color: var(--primary-blue);
    color: var(--primary-blue);
    background-color: var(--faded-primary-blue);
}


.time-range-bottom-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.time-range-btn {
    height: 30px;
    padding: 6px 25px;
}

.time-range-confirm-btn {
    color: var(--white);
    background-color: var(--primary-blue);
}

.time-range-discard-btn {
    color: var(--primary-blue);
    background-color: var(--faded-primary-blue);
}