.map-container {
    margin: 30px;
    height: 80vh;
    background-color: var(--white);
    border-radius: 10px;
    user-select: none;
    position: relative;
    overflow: auto;
}
.heat-map-loader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}
.loader-text {
    margin-top: -10px;
}

.spinner-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1008;
    border-radius: 20px;
    background-color: var(--faded-primary-blue);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#indoor-map-id-reference {
    transform: scale(1.0);
}

.map-container::-webkit-scrollbar {
    display: none;
}

.map-container .indoor-map {
    height: 100%;
    border-radius: 10px;
    border: none;
}

.loader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: var(--white);
    z-index: 1000;
    border-radius: 10px;
}

.lgv-icon-styles {
    display:flex;
    background-color: #2b2b2b;
    width:18px;
    height:18px;
    border:1px solid #ffffff;
    border-radius:2px;
    font-size: 12px;
    color:white;
    display:flex;
    justify-content:center;
    align-items:center;
    font-family: 'Poppins' !important;
    z-index: 1008 !important;
}

.popup-title {
    font-size: 20px;
    font-weight: 500;
}

.map-container-wrapper {
    position: relative;
}

.zoom-control-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50px;
    top: 10px;
    z-index: 1005;
}

.zoom-controls {
    width: 35px;
    height: 35px;
    background-color: white;
    font-size: 24px;
    border: none;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    cursor: pointer;
}

.zoom-in-control {
    border-radius: 10px 10px 0px 0px;
}

.zoom-out-control {
    border-radius: 0px 0px 10px 10px ;
}

.zoom-reset-controls {
    margin-top: 5px;
    border-radius: 10px;
}

.zoom-reset-controls img {
    width: 17px;
    height: 17px;
}

.spinner {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: 
        radial-gradient(farthest-side,var(--primary-color) 94%,#0000) top/4px 4px no-repeat,
        conic-gradient(#0000 30%,var(--primary-color));
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
    animation:s3 1s infinite linear;
}

@keyframes s3{ 
    100%{transform: rotate(1turn)}
}


@media only screen and (max-width: 780px) {
    .a-status-section  .a-status-box {
        min-width: 15vw;
    }
}

@media only screen and (min-width: 2560px) {
    .map-container {
        width: 98%;
        height: 50vh;
    }
}