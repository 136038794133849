.popup-title {
    font-size: 24px;
    font-weight: 500;
    color: var(--primaryBlue);
    width: 100%;
    text-align: left;
}

.popup-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--modal-bg);
    position: fixed;
    z-index: 1008;
    top: 0;
    left: 0;
    overflow: hidden;
    cursor: pointer;
    webkit-user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-container .dock-loader {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
}

.spinner {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: 
        radial-gradient(farthest-side,var(--primary-color) 94%,#0000) top/4px 4px no-repeat,
        conic-gradient(#0000 30%,var(--primary-color));
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
    animation:s3 1s infinite linear;
}

.popup-container {
    width: 60vw;
    margin-bottom: 10px;
    background-color: var(--white);
    padding: 40px;
    border-radius: 30px;
}

.popup-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.popup-section-title {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.details-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.details {
    margin-right: 50px;
    margin-bottom: -20px;
    text-align: left;
    min-width: 250px;
}

.details-data-title {
    font-size: 14px;
    font-weight: 400;
    color: var(--tertiary-white);
}

.details-data {
    font-weight: 500;
    font-size: 16px;
    color: var(--grey);
    margin-top: -10px;
}

.lgv-icon-style {
    display:flex;
    width:18px;
    height:18px;
    border:1px solid #ffffff;
    border-radius:2px;
    font-size: 12px;
    color:white;
    display:flex;
    justify-content:center;
    align-items:center;
    font-family: 'Poppins' !important;
    z-index: 1008 !important;
}