.button-container {
    background-color: var(--white);
    color: var(--black);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    border-radius: 30px;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    margin: 0px 7px;
}
.button-container .spinner {
    width: 20px;
    height: 20px;
}
.button-container-active {
    background-color: var(--white);
    color: var(--black);
    cursor: pointer;
}
.button-container-loading {
    width: 100px;
    cursor: not-allowed;
    color: var(--black);
    background-color: var(--disabled-loading-state);
}
.button-container img {
    padding-right: 7px;
}