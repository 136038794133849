.line-prod-wrapper {
    position: absolute;
    z-index: 1002;
    margin: 20px;
    top: 0;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    cursor: pointer;
    font-family: 'Poppins' !important;
    background-color: tomato;
}

.line-prod-loader {
    width: 500px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: inherit;
    background-color: var(--disabled-white);
    z-index: 1000;
    border-radius: 10px;
    margin-left: 100px;
    margin-top: -300px;
}

.spinner {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: 
        radial-gradient(farthest-side,var(--primary-color) 94%,#0000) top/4px 4px no-repeat,
        conic-gradient(#0000 30%,var(--primary-color));
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
    animation:s3 1s infinite linear;
}

@keyframes s3{ 
    100%{transform: rotate(1turn)}
}

.line-prod-container {
    width: 70px;
    height: 120px;
    background-color: var(--disabled-white);
    padding: 3px;
    margin: 10px;
    border-radius: 15px;
    z-index: 1007 !important;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
}

.pallets-container {
    height: 80%;
    width: 100%;
    display: flex;
    border-radius: 15px 15px 0px 0px;
    flex-direction: column;
}

.prod-top-section, .prod-middle-section {
    width: 100%;
    background-color: var(--line-color);
    color: var(--grey);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    flex: 1;
    margin: 0px 0px 2px 0px;
}

.prod-bottom-section {
    height: 20%;
    width: 100%;
    display: flex;
    border-radius: 0px 0px 15px 15px;
    flex-direction: row;
    margin-bottom: 1px;
    justify-content: space-between;
    align-items: center;
}

.prod-bottom-section .line-section {
    height: 20px;
    width: 20px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    background-color: var(--white);
}

.prod-bottom-section .line-color {
    height: 20px;
    width: 20px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.line-color-on {
    color: var(--white);
    background-color: var(--primary-color);
}

.line-color-off {
    color: var(--black);
    background-color: var(--white);
}

.line-prod-popup-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--modal-bg);
    position: fixed;
    z-index: 1008;
    top: 0;
    left: 0;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
}

.line-prod-popup-container {
    position: absolute;
    top: 30%;
    left: 25%;
    width: 50%;
    background-color: var(--white);
    border-radius: 25px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1007;
}

.prod-popup-top-section {
    flex: .1;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.prod-popup-top-section h3 {
    font-size: 20px;
    font-weight: 500;
    margin-top: -10px;
}

.prod-popup-top-section img {
    margin-top: -30px;
    width: 15px;
    height: 15px;
}

.prod-popup-bottom-section {
    flex: .9;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: -20px;
}

.bottom-item-container {
    width: 170px;
    text-align: left;
}

.prod-popup-bottom-section .prod-title {
    color: var(--tertiary-white);
    font-size: 13px;
    word-break: break-all;
}

.prod-popup-bottom-section .prod-item-value {
    font-size: 14px;
    font-weight: 500;
    margin-top: -10px;
    word-break: break-all;
}

.fip-container {
    padding: 1px 0px;
    flex: 1;
    background-color: #d71f1f;
    color: white;
}

.tool-tip {
    position: relative;
    display: inline-block;
}

.tool-tip .tool-tip-text {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -10px;
}

.tool-tip:hover .tool-tip-text {
    visibility: visible;
}