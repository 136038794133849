

.add-dashboard-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: var(--modal-bg);
    z-index: 1009;
    position: fixed;
    top: 0;
}


.add-dashboard-container {
    position: absolute;
    margin-top: 60px;
    margin-right: 40px;
    right: 0;
    width: 700px;
    height: 180px;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
}

.close-section {
    margin-top: 10px;
    margin-right: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.close-section img {
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.dash-items-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    margin-top: 1.8%;
}

.dash-items-section a {
    width: 150px;
    text-decoration: none;
    cursor: pointer;
}

.add-dashboard-item img {
    width: 50px;
    height: 50px;
}

.add-dashboard-item p {
    font-size: 15px;
    font-weight: 500;
    color: var(--grey);
}

.add-dashboard-item p:hover {
    color: var(--primary-blue);
}