.dashboard-container {
    position: relative;
}

@media only screen and (min-width: 2560px) {
    .flexible-screen {
        display: flex;
        flex-direction: row;
    }
    
    .flexible-left-screen {
        flex: .8;
    }
    
    .flexible-right-screen {
        flex: .2;
    }
}
