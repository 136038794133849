.dock-door-container {
    width: 10%;
    height: 50px;
    position: absolute;
    right: 50px;
    margin-top: -70px;
    z-index: 1002;
}

.dock-door-at-top {
    top: 60px;
}

.dock-door-at-bottom {
    bottom: 60px;
}

.dock-door-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.dock-door {
    width: 19px;
    height: 50px;
    background-color: var(--white);
    border-radius: 7px 7px 4px 4px;
    border: 1px solid var(--lite-border);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 10px;
    overflow-y: hidden;
}

.dock-door .dock-top-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: .2;
    font-size: 10px;
    border-top: 1px solid var(--lite-border);
    border-radius: 0px 0px 3px 3px;
    width: 100%;
    background-color: var(--liteBlue);
}

.dock-bottom-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: .8;
}

.dock-reverse {
    display: flex;
    flex-direction: column-reverse;
}

.dock {
    margin: .9px 0px;
    color: transparent;
    font-size: .2px;
}

.dock-small {
    width: 40%;
    height: 3px;
    border-radius: 1px;
}

.dock-big {
    width: 85%;
    height: 2.5px;
    border-radius: 1px;
    display: flex;
    flex-direction: row;
}

.dock-big-complex-left, .dock-big-complex-right {
    flex: 1;
    margin: 0px .6px;
    border-radius: 1px;
}

.legend-btn {
    width: 80px;
    height: 25px;
    border: 1px solid var(--primary-blue);
    position: absolute;
    right: 1%;
    top: 40%;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.dock-door-container .dock-loader {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
}

.spinner {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: 
        radial-gradient(farthest-side,var(--primary-color) 94%,#0000) top/4px 4px no-repeat,
        conic-gradient(#0000 30%,var(--primary-color));
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
    animation:s3 1s infinite linear;
}

@keyframes s3{ 
    100%{transform: rotate(1turn)}
}
