.dropdown-container {
    background-color: var(--white);
    color: var(--black);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    font-size: 13px;
    border-radius: 30px;
    cursor: pointer;
    user-select: none;
    margin: 0px 7px;
}

.dropdown-container select {
    min-width: 120px;
    background: none;
    border: none;
    font-size: 14px;
    font-family: 'Poppins';
    border: none;
} 

.dropdown-container option {
    font-size: 14px;
}

.dropdown-container select:focus {
    border: none;
    outline: none;
}